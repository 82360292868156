<script setup lang="ts">
const { languages, currentLanguage } = useCustomInternationalization();
const { changeLanguage } = useLanguageSelect();
</script>

<template>
    <div
        v-if="languages.length > 0"
        class="flex flex-col mb-4"
    >
        <button
            v-for="language in languages"
            :key="language.id"
            class="bg-inherit cursor-pointer text-left block py-2 w-full hover:text-brand-primary inline-flex items-center"
            :class="{
                'text-brand-primary': currentLanguage?.id === language.id,
                'order-1': language?.translationCode?.code === 'de-DE',
                'order-2': language?.translationCode?.code === 'en-GB',
                'order-3': language?.translationCode?.code === 'it-IT',
                'order-4': language?.translationCode?.code === 'fr-FR',
                'order-5': language?.translationCode?.code === 'es-ES',
                'order-6': language?.translationCode?.code === 'pl-PL',
            }"
            role="menuitem"
            @click="changeLanguage(language?.translationCode?.code, true)"
        >
            {{ language.name }}
        </button>
    </div>
</template>
